.chinamap .mapbox[data-v-2d2be9e1] {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 8rem;
  background: url(../../static/img/mapbg.2668970e.jpg) no-repeat center center / cover;
}
.chinamap .businessmap[data-v-2d2be9e1] {
  min-height: 12rem;
}
.chinamap .businessmap[data-v-2d2be9e1] .container {
  padding: 1.2rem 0;
  position: relative;
}
.chinamap .businessmap[data-v-2d2be9e1] .container .areatitle {
  margin-bottom: 0.5rem;
  font-size: 0.36rem;
  color: var(--themeColor);
  font-weight: 400;
}
.chinamap .businessmap[data-v-2d2be9e1] .container .el-dropdown {
  position: absolute;
  right: 0;
  top: 1rem;
  background: #ffffff;
  padding: 0.16rem 0.2rem;
  border-radius: 0.08rem;
  color: #333333;
}
.chinamap .businessmap[data-v-2d2be9e1] .container .showarea .arealist .arealist-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.3rem 0;
  border-top: 0.01rem solid #e8e8e8;
}
.chinamap .businessmap[data-v-2d2be9e1] .container .showarea .arealist .arealist-item .name {
  width: 30%;
  font-size: var(--f18);
  color: #333333;
}
.chinamap .businessmap[data-v-2d2be9e1] .container .showarea .arealist .arealist-item .address {
  margin-left: 0.3rem;
  font-size: var(--f18);
  color: #333333;
}
.chinamap .businessmap[data-v-2d2be9e1] .container .showarea .arealist .arealist-item:last-child {
  border-bottom: 0.01rem solid #e8e8e8;
}
[data-v-2d2be9e1] .el-dropdown-menu__item:not(.is-disabled):focus {
  color: var(--themeColor) !important;
}
svg[data-v-2d2be9e1] {
  position: absolute;
  left: -6rem;
  top: -3.5rem;
  width: 180%;
  height: 180%;
}