.optimizeBuss[data-v-601481ac] {
  background: url(../../static/img/contentBg.c1cdf12e.jpg) no-repeat center center / cover;
}
.optimizeBuss .container[data-v-601481ac] {
  padding: 1.2rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}