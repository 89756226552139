.brandbuilding .container[data-v-825db4a2] {
  margin: 1.2rem auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.brandbuilding .container .left .descbox[data-v-825db4a2] {
  margin-top: 0.45rem;
}
.brandbuilding .container .left .descbox .title[data-v-825db4a2] {
  font-size: var(--f24);
  color: var(--themeColor);
  margin-bottom: 0.2rem;
}
.brandbuilding .container .left .descbox .desc[data-v-825db4a2] {
  color: #595757;
  font-size: var(--f16);
  margin-bottom: 0.4rem;
}
.brandbuilding .container .right[data-v-825db4a2] {
  width: 7.6rem;
  height: 4.8rem;
  margin-left: 1.2rem;
  background: url(../../static/img/brand.d5c4f974.png) no-repeat center / cover;
  background-size: 100% 100%;
  position: relative;
}
.brandbuilding .container .right .logo[data-v-825db4a2] {
  height: 0.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}