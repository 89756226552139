.announce[data-v-55cbf2b9] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.announce[data-v-55cbf2b9] .announce-left .el-radio-group {
  margin-right: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.announce[data-v-55cbf2b9] .announce-left .el-radio-group .el-radio.el-radio--large .el-radio__label {
  color: #000000;
  font-size: var(--f18);
  display: block;
  width: 100%;
}
.announce[data-v-55cbf2b9] .announce-left .el-radio-group .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--themeColor);
  background: var(--themeColor);
}
.announce[data-v-55cbf2b9] .announce-left .el-radio-group .el-radio__input.is-checked + .el-radio__label {
  color: var(--themeColor);
}
.announce .announce-right[data-v-55cbf2b9] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.announce .announce-right-list-item[data-v-55cbf2b9] {
  margin-bottom: 0.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
  background-color: #f4f5f5;
}
.announce .announce-right-list-item-time[data-v-55cbf2b9] {
  color: #666666;
  font-size: var(--f24);
  margin-right: 0.85rem;
}
.announce .announce-right-list-item-box .title[data-v-55cbf2b9] {
  color: #000000;
  font-size: var(--f24);
}
.announce .announce-right-list-item-box .more[data-v-55cbf2b9] {
  margin-top: 0.4rem;
  font-size: var(--f16);
  color: var(--themeColor);
}
.announce .announce-right-list-item-box .more .icon[data-v-55cbf2b9] {
  color: var(--themeColor);
}
.announce .announce-right-list-item[data-v-55cbf2b9]:hover {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.08);
  border-bottom: 0.02rem solid var(--themeColor);
}
.announce .announce-right-list-item:hover .announce-right-list-item-box .title[data-v-55cbf2b9] {
  color: var(--themeColor) !important;
}
.announce .nodata[data-v-55cbf2b9] {
  margin: 0 auto;
}
.announce .nodata .title[data-v-55cbf2b9] {
  font-size: 0.36rem;
  text-align: center;
}
.announce[data-v-55cbf2b9] .el-pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.announce[data-v-55cbf2b9] .el-pagination .el-pager li {
  border-radius: 50%;
}
.announce[data-v-55cbf2b9] .el-pagination .el-pager li:not(.is-disabled).is-active {
  background-color: var(--themeColor);
}
.announce[data-v-55cbf2b9] .el-pagination .el-pager li:not(.is-disabled):hover {
  color: var(--themeColor);
}