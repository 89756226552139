.ideally[data-v-ec2f2f14] {
  width: 15rem;
  margin: 0 auto;
  padding: 0.32rem 0;
}
.ideally .title[data-v-ec2f2f14] {
  color: var(--themeColor);
  font-size: 0.36rem !important;
}
.ideally .ywtitle[data-v-ec2f2f14] {
  color: #999;
  font-size: 0.28rem;
  margin-bottom: 0.24rem;
}
.ideally .text[data-v-ec2f2f14] {
  color: #666666;
  font-size: 0.16rem;
  line-height: 0.32rem !important;
}