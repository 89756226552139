.memory .container[data-v-93310330] {
  margin: 1.2rem auto;
}
.memory .container .timeMemory[data-v-93310330] {
  margin-top: 0.65rem;
}
.memory .container .timeMemory .timeline[data-v-93310330] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.memory .container .timeMemory .timeline .icon[data-v-93310330] {
  color: var(--themeColor);
  font-size: var(--f18);
}
.memory .container .timeMemory .timeline .timelinelistbox[data-v-93310330] {
  width: 90%;
  margin: 0 5%;
  overflow: hidden;
}
.memory .container .timeMemory .timeline .timelinelistbox .timeline-list[data-v-93310330] {
  width: 100%;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}
.memory .container .timeMemory .timeline .timelinelistbox .timeline-list-item[data-v-93310330] {
  padding: 0 0.9rem;
  color: var(--descColor);
  font-size: var(--f18);
  word-break: keep-all;
  cursor: pointer;
}
.memory .container .timeMemory .timeline .timelinelistbox .timeline-list-itemactive[data-v-93310330] {
  color: var(--themeColor) !important;
  font-size: 0.3rem;
}
.memory .container .timeshow[data-v-93310330] {
  margin-top: 0.78rem;
}
.memory .container .timeshow .info[data-v-93310330] {
  font-size: var(--f24);
  text-align: center;
}
.memory .container .timeshow-item[data-v-93310330] {
  padding-bottom: 0.78rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  height: 2.2rem;
}
.memory .container .timeshow-item .infobox .time[data-v-93310330] {
  position: relative;
  padding-top: 0.19rem;
  color: #000000;
  font-size: var(--f18);
}
.memory .container .timeshow-item .infobox .time[data-v-93310330]::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 0.72rem;
  height: 0.01rem;
  background: var(--themeColor);
}
.memory .container .timeshow-item .infobox .title[data-v-93310330] {
  color: #000000;
  font-size: var(--f18);
  margin-top: 0.08rem;
  margin-bottom: 0.35rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.memory .container .timeshow-item .infobox .desc[data-v-93310330] {
  color: var(--Contentcolor);
  font-size: var(--f16);
  width: 6rem;
}
.memory .container .timeshow-item .line[data-v-93310330] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.memory .container .timeshow-item .line[data-v-93310330]::before {
  position: absolute;
  content: "";
  display: block;
  width: 0.02rem;
  height: 100%;
  background: var(--themeColor);
  top: 0;
  left: 50%;
}
.memory .container .timeshow-item .line[data-v-93310330]:after {
  position: absolute;
  content: "";
  display: block;
  width: 0.1rem;
  height: 0.1rem;
  background: var(--themeColor);
  top: 0;
  left: calc(50% - 0.04rem);
  border-radius: 50%;
}
.memory .container .timeshow-item .rightpic .pic[data-v-93310330] {
  width: 4.1rem;
  height: 2.3rem;
}
.memory .container .timeshow-item[data-v-93310330]:nth-child(odd) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  text-align: right;
}
.memory .container .timeshow-item:nth-child(odd) .infobox[data-v-93310330] {
  position: absolute;
  right: calc(50% + 0.5rem);
}
.memory .container .timeshow-item:nth-child(odd) .infobox .time[data-v-93310330]::before {
  right: 0;
  left: unset;
}
.memory .container .timeshow-item:nth-child(odd) .rightpic[data-v-93310330] {
  position: absolute;
  left: calc(50% + 0.5rem);
}
.memory .container .timeshow-item[data-v-93310330]:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.memory .container .timeshow-item:nth-child(even) .infobox[data-v-93310330] {
  text-align: left;
  position: absolute;
  left: calc(50% + 0.5rem);
}
.memory .container .timeshow-item:nth-child(even) .rightpic[data-v-93310330] {
  position: absolute;
  right: calc(50% + 0.5rem);
}
.memory .container .timeshow-item:last-child .line[data-v-93310330]::before {
  content: unset;
}
.memory .container .timeshow-nopicitem[data-v-93310330] {
  height: unset;
  min-height: 1.2rem;
  overflow: hidden;
}