.honor .container[data-v-5b033984] {
  margin: 1.2rem auto;
}
.honor .container .containerbox[data-v-5b033984] {
  margin-top: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.honor .container .containerbox[data-v-5b033984] .honor-left .el-radio-group {
  margin-right: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.honor .container .containerbox[data-v-5b033984] .honor-left .el-radio-group .el-radio.el-radio--large .el-radio__label {
  color: #000000;
  font-size: var(--f18);
}
.honor .container .containerbox[data-v-5b033984] .honor-left .el-radio-group .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--themeColor);
  background: var(--themeColor);
}
.honor .container .containerbox[data-v-5b033984] .honor-left .el-radio-group .el-radio__input.is-checked + .el-radio__label {
  color: var(--themeColor);
}
.honor .container .containerbox .honor-right[data-v-5b033984] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.honor .container .containerbox .honor-right .imgbox .pic[data-v-5b033984] {
  width: 100%;
}
.honor .container .containerbox .honor-right .imglist[data-v-5b033984] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  margin-bottom: 1.2rem;
}
.honor .container .containerbox .honor-right .imglist-item[data-v-5b033984] {
  padding: 0.5rem;
  border: 0.01rem solid #e9e9e9;
  -webkit-box-shadow: 0 0 0.27rem 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0.27rem 0 rgba(0, 0, 0, 0.05);
  position: relative;
}
.honor .container .containerbox .honor-right .imglist-item .pic[data-v-5b033984] {
  width: 2.95rem;
  height: 2rem;
}
.honor .container .containerbox .honor-right .imglist-item .name[data-v-5b033984] {
  margin-top: 0.4rem;
  font-size: var(--f16);
  color: #333333;
}
.honor .container .containerbox .honor-right .imglist-item .time[data-v-5b033984] {
  margin-top: 0.16rem;
  font-size: var(--f14);
  color: #858585;
}
.honor .container .containerbox .honor-right .imglist-item[data-v-5b033984]::after {
  content: "";
  height: 0.02rem;
  width: 100%;
  display: none;
  background-color: var(--themeColor);
  position: absolute;
  bottom: -0.5rem;
  z-index: 10;
  left: 0;
}
.honor .container .containerbox .honor-right .imglist-item[data-v-5b033984]:hover::after {
  display: block;
}
.honor .container .containerbox .honor-right .imglist[data-v-5b033984]::after {
  content: "";
  width: 100%;
  height: 0.02rem;
  display: block;
  background-color: #efefef;
  position: absolute;
  bottom: -0.5rem;
}
.honor .container .containerbox .honor-right .list-item[data-v-5b033984] {
  color: #666666;
  font-size: var(--f16);
  padding: 0.32rem 0;
  border-bottom: 0.01rem solid #efefef;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.honor .container .containerbox .honor-right .list-item[data-v-5b033984]::before {
  width: 0.16rem;
  height: 0.16rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAAAAXNSR0IArs4c6QAAA+ZJREFUWEe9l1toHGUUx39nkjVqpHiptGC9oL5FBWkpGDXgTuKlLxURRG3cWTXubApCRB+8oFWh1hatUO1O1pKd1BJqfPCpGHRnA21TH6qCF0REKVS0avCCSmw32Tkyu5u62Wx2Jlv0e1vOOf/z+75zzjffCnUrP7r5ItG5d0CuBYqA1vu08LsD4XvDMO6+pX/3N7Xxsgggl+oTkfdbSBIaouhzvdbwCyEA6TtF9N1QtVYchG1mwnmyOcBo6h5R2d+KfmhMJIDllSDoDx9oC01ebqYIJfBGBrsx/KlogmxqM/jU9/k8ij8qQ2Yy89qCEuTd9FZBNwIxYBahE+XyKIKm5ZSb2HPtY8AVEWJ+AP0NjBiiqjAlnmsvZ8x+Bg4q+quITJgJp9ysBXdzF8ylFbkYWAdcGQGm7BIA/AisQhgDPgTpRHUrYNSLCDIRtzJ3NBP33PQO0Mcb+ajoDkGOi881KqRAfxdv1D6AsgFhi5lwnq8e6SfA9Q1EfFG2xZPO040SeDm7H5EMaGcDu5qWU96UN2LHMfAEOSYF1x5SeBUonfoj1rnh0V2nCrn0Kyr62FI7FfSpuDX8Uq09n0vdLiLvLR0jh+NW5ubKBlNfgHQpslcm9z28xp9r/64auMe0nIGCa9+ocLjJUe80LWcBYGGvfZv6TDQBSMWtTNbL2QMI2cBPVXrmu3g3kC43hfJs7Cx5vTirwWhd0kiwo1RccdNDI3/mR9ObDPwV8cRwEB9MQ9CkQSPWrxmhbT2UuhTerhqPmpaz/vS3wBu1p1C6y2RwQiCwrW4EILAdZZUKiTI05H30kMAQyPkNYv4CfgKuqtqmY36sq+fBXdMLPkaFnO3Oi0Ydoxb8PtDZuf7egT0BUHmXC1blJix1K3KpVMoSXFBntoQx8fnKR4/2JocX9MkigNpMNRNyJgD7Tcu5d+nmDJH2XDuYkDUtEpQ6SsULgoZtGaDgptcq+lFLAErSTDpus9imJZgPLOTST6jo9mVBCGNmwrk/LCYSQCDiuelnQF8ME6zYdZ9pDfdH8Y0MEIgVcqm7VGQncNkS4icR2WImMi9HSd5wDMMCj4wPnXPy75mkKhtBbq36fwaM++K/1ZfIHg/TqLUv6wTqhf99S/hXm1b22+UknvdtGWBy7JGVftGYDoR89a/rS2ajPcvqKFsHyA2u9sU/UamjrItbmY//1xOoTEblOafStrI38cYv/xnAZM46u2Sce4OhpdPPNFXjQkTHq0kHBf26FqC9Tb/s6c+WT6jZilQCz7WDm3BtmFidfSaecM4Taf7fMirAm8B9dQ/V4PjnqknbF31ZhSPxB5zeMIB/AL8BZxPxW8gBAAAAAElFTkSuQmCC) no-repeat center center / cover;
  content: "";
  display: block;
  margin-right: 0.42rem;
}
.honor .container .containerbox .honor-right .list-item[data-v-5b033984]:hover {
  color: var(--themeColor);
  font-weight: bold;
  border-bottom: 0.02rem solid var(--themeColor);
}