.waterInfo .container[data-v-b0f52cbe] {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.waterInfo .container[data-v-b0f52cbe] .waterInfo-left .el-radio-group {
  margin-right: 0.6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.waterInfo .container[data-v-b0f52cbe] .waterInfo-left .el-radio-group .el-radio.el-radio--large .el-radio__label {
  color: #000000;
  font-size: var(--f18);
  display: block;
  width: 100%;
}
.waterInfo .container[data-v-b0f52cbe] .waterInfo-left .el-radio-group .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--comthemeColor);
  background: var(--comthemeColor);
}
.waterInfo .container[data-v-b0f52cbe] .waterInfo-left .el-radio-group .el-radio__input.is-checked + .el-radio__label {
  color: var(--comthemeColor);
}
.waterInfo .container .waterInfo-right[data-v-b0f52cbe] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.waterInfo .container .waterInfo-right-list-item[data-v-b0f52cbe] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.42rem;
}
.waterInfo .container .waterInfo-right-list-item .picbox[data-v-b0f52cbe] {
  width: 30%;
  height: 2.7rem;
  overflow: hidden;
  background-size: auto 100%;
}
.waterInfo .container .waterInfo-right-list-item-info[data-v-b0f52cbe] {
  width: calc(70% - 1rem);
  height: 1.7rem;
  padding: 0.5rem;
  background: #ffffff;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.08);
}
.waterInfo .container .waterInfo-right-list-item-info .time[data-v-b0f52cbe] {
  font-size: var(--f18);
  color: #666666;
  margin-bottom: 0.2rem;
}
.waterInfo .container .waterInfo-right-list-item-info .title[data-v-b0f52cbe] {
  font-size: var(--f24);
  color: #000000;
  margin-bottom: 0.22rem;
}
.waterInfo .container .waterInfo-right-list-item-info .desc[data-v-b0f52cbe] {
  font-size: var(--f14);
  color: #666666;
  margin-bottom: 0.4rem;
}
.waterInfo .container .waterInfo-right-list-item-info .more[data-v-b0f52cbe] {
  font-size: var(--f16);
  color: var(--comthemeColor);
}
.waterInfo .container .waterInfo-right-list-item:hover .title[data-v-b0f52cbe] {
  color: var(--comthemeColor);
}
.waterInfo .container .waterInfo-right[data-v-b0f52cbe] .el-pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.waterInfo .container .waterInfo-right[data-v-b0f52cbe] .el-pagination .el-pager li {
  border-radius: 50%;
}
.waterInfo .container .waterInfo-right[data-v-b0f52cbe] .el-pagination .el-pager li:not(.is-disabled).is-active {
  background-color: var(--comthemeColor);
}
.waterInfo .container .waterInfo-right[data-v-b0f52cbe] .el-pagination .el-pager li:not(.is-disabled):hover {
  color: var(--comthemeColor);
}