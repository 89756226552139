.compstyle[data-v-c6e769e0] {
  margin: 1.2rem auto;
}
.compstyle .container[data-v-c6e769e0] {
  margin-top: 0.5rem;
}
.compstyle .container .titlebox[data-v-c6e769e0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}
.compstyle .container[data-v-c6e769e0] .compstyle-left .el-radio-group {
  margin-right: 0.6rem;
}
.compstyle .container[data-v-c6e769e0] .compstyle-left .el-radio-group .el-radio.el-radio--large .el-radio__label {
  color: #000000;
  font-size: var(--f18);
}
.compstyle .container[data-v-c6e769e0] .compstyle-left .el-radio-group .el-radio__input.is-checked .el-radio__inner {
  border-color: var(--themeColor);
  background: var(--themeColor);
}
.compstyle .container[data-v-c6e769e0] .compstyle-left .el-radio-group .el-radio__input.is-checked + .el-radio__label {
  color: var(--themeColor);
}
.compstyle .container .compstyle-right-list-item[data-v-c6e769e0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0.4rem;
}
.compstyle .container .compstyle-right-list-item .picbox[data-v-c6e769e0] {
  width: 30%;
}
.compstyle .container .compstyle-right-list-item .infobox[data-v-c6e769e0] {
  width: calc(70% - 1rem);
  padding: 0.5rem;
  -webkit-box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.08);
}
.compstyle .container .compstyle-right-list-item .infobox .name[data-v-c6e769e0] {
  font-size: var(--f24);
  font-weight: 400;
  color: var(--themeColor);
  margin-bottom: 0.22rem;
}
.compstyle .container .compstyle-right-list-item .infobox .desc[data-v-c6e769e0] {
  font-size: var(--f14);
  font-weight: 400;
  color: var(--Contentcolor);
  margin-bottom: 0.75rem;
}
.compstyle .container .compstyle-right-list-item .infobox .more[data-v-c6e769e0] {
  color: var(--themeColor);
  font-size: var(--f16);
}
.compstyle .container .compstyle-right-list-item .infobox .more .icon[data-v-c6e769e0] {
  color: var(--themeColor);
}
.compstyle .container .compstyle-right[data-v-c6e769e0] .el-pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.compstyle .container .compstyle-right[data-v-c6e769e0] .el-pagination .el-pager li {
  border-radius: 50%;
}
.compstyle .container .compstyle-right[data-v-c6e769e0] .el-pagination .el-pager li:not(.is-disabled).is-active {
  background-color: var(--themeColor);
}
.compstyle .container .compstyle-right[data-v-c6e769e0] .el-pagination .el-pager li:not(.is-disabled):hover {
  color: var(--themeColor);
}