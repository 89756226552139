.leadspeech .container[data-v-113680b2] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 1.2rem auto;
  height: 7.25rem;
}
.leadspeech .container .left[data-v-113680b2] {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.leadspeech .container .left[data-v-113680b2] .showarea {
  height: calc(100% - 1.24rem);
  overflow-y: auto;
  margin-top: 0.44rem;
}
.leadspeech .container .left[data-v-113680b2] .showarea .infobox {
  padding-right: 0.46rem;
}
.leadspeech .container .left[data-v-113680b2] .showarea .infobox-item {
  font-size: var(--f16);
  color: #595757;
  margin-bottom: 0.44rem;
  line-height: 0.32rem;
  text-indent: 0.32rem;
  letter-spacing: 0.06rem;
}
.leadspeech .container .left[data-v-113680b2] .showarea::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: calc(100% - 0.02rem);
  height: 1rem;
  display: block;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0.8)), to(rgba(255, 255, 255, 0.3)));
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3));
  z-index: 2;
}
.leadspeech .container .left .showarea[data-v-113680b2]::-webkit-scrollbar {
  width: 0.02rem !important;
  background-color: #ebebeb !important;
}
.leadspeech .container .left .showarea[data-v-113680b2]::-webkit-scrollbar-thumb {
  background-color: var(--themeColor);
}
.leadspeech .container .right[data-v-113680b2] {
  margin-left: 1.18rem;
  overflow: hidden;
}
.leadspeech .container .right .pic[data-v-113680b2] {
  width: 6.5rem;
}